<template>
  <div style="height: 1000px">
    <div class="startCareerCompleteImage"></div>
    <div class="startCareer__complete-button">
      <v-btn
        rounded
        large
        color="#F39F3C"
        @click="resetFlow"
        style="text-transform: none"
        >{{ $t("backHome") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    resetFlow() {
      this.$store.state.stageCounter = 1;
      this.$router.push("/");
    },
  },
  created() {
    if (!this.$store.getters.getLoadingModalStatus) {
      this.$swal.close();
    }
  },
};
</script>

<style>
.startCareer__complete-button {
  text-transform: unset;
  position: absolute;
  top: 25%;
  right: 38%;
  /* right: 70%; */
  /* bottom: 0; */
  /* z-index: 1000; */
}
.startCareerCompleteImage {
  background: url("../assets/B-3.png");
  background-repeat: no-repeat;
  background-size: 400px auto;
  background-position: center;
  height: 100%;
  box-shadow: inset 0 0 0 2000px rgba(33, 123, 138, 0.75);
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .startCareerCompleteImage {
    height: 180%;
    background-size: unset;
  }
  .startCareer__complete-button {
    right: 45%;
  }
}
</style>