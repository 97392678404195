<template>
  <div>
    <div class="aboutUs--section1">
      <h5>{{ watchLang.aboutUsSmallText }}</h5>
      <h1>{{ watchLang.aboutUsHead }}</h1>
      <v-btn
        rounded
        x-large
        color="white"
        class="aboutUs__button animate__animated animate__swing"
        :class="{ homeButtonRTL: $vuetify.rtl }"
        @click="$router.push('/StartYourCarrer')"
        >{{ watchLang.abutUsButtonText }}</v-btn
      >
    </div>
    <div class="aboutUs--section2">
      <v-container>
        <v-row>
          <v-col sm="6" cols="12" class="aboutUs_section2TextCol">
            <h1 :class="{ aboutUsArText: $vuetify.rtl }">
              {{ watchLang.aboutUs }}
            </h1>
            <p
              :class="{
                aboutUsArParagraph: $vuetify.rtl,
              }"
            >
              {{ watchLang.aboutUsText }}
            </p>
          </v-col>
          <v-col sm="6" cols="12" class="aboutUs_section2ImageCol">
            <div class="section2-imageWrapper">
              <img
                src="@/assets/Connectme.png"
                alt="..."
                class="section2--image"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="aboutUs--section3">
      <h1>{{ watchLang.ourWorkFlow }}</h1>
      <v-container>
        <v-row>
          <v-col
            lg="12"
            cols="12"
            v-for="(item, index) in ourWork"
            :key="index"
            class="section3--col"
          >
            <p class="section3--paragraph">
              <i
                class="fa fa-check section3--checkIcon"
                :class="{ 'section3--checkIconAr': $vuetify.rtl }"
              ></i>
              {{ item.description }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import footer from "../components/footer";
export default {
  components: {
    appFooter: footer,
  },
  data() {
    return {};
  },
  computed: {
    ourWork() {
      let ourWork = [
        {
          description: `Creating a professional bond between job seekers and modern 
institutions by adopting the latest methods and technologies, and 
securing jobs for a better life.`,
        },
        {
          description: `Facilitating the process of 
searching for work within the best environments and for all individuals.`,
        },
        {
          description:
            "Delivering people with skills to the places that are looking for them, in the fastest time and at the lowest cost.",
        },
      ];

      if (this.$vuetify.rtl == true) {
        ourWork = [
          {
            description: `خلق رابطة احترافية بين الباحثين عن عمل والمؤسسات الحديثة باعتماد أحدث الطرق والتقنيات، وتأمين أعمال أفضل لحياة
أفضل.`,
          },
          {
            description: `تسهيل عملية البحث عن عمل ضمن أفضل البيئات ولجميع الأفراد.`,
          },
          {
            description: `إيصال أصحاب المهارات إلى الأماكن التي تبحث عنهم، بأسرع وقت وأقل تكلفة.`,
          },
        ];
      }
      return ourWork;
    },
    watchLang() {
      let texts = {
        aboutUsSmallText: "Any Location, Any Time",
        abutUsButtonText: "Get Started",
        aboutUsHead: "Connect To The World",
        aboutUs: "About Us",
        ourWorkFlow: "Our mission",
        aboutUsText: `A company specialized in linking companies with people looking for work 
in the fastest and least expensive way, we provide companies with the 
best talent and insurance.
Decent and suitable employment opportunities for all professions, jobs 
and positions.`,
      };
      if (this.$vuetify.rtl == true) {
        texts.aboutUsSmallText = "في أي مكان و في أي وقت";
        texts.abutUsButtonText = "ابدأ رحلتك";
        texts.aboutUsHead = "اتصل بالعالم";
        texts.aboutUs = "من نحن";
        texts.ourWorkFlow = "مهمتنا";
        texts.aboutUsText =
          "شركة مختصة بربط الشركات بالأشخاص الباحثين عن عمل بأسرع الطرق وأقل التكاليف، نقوم برفد الشركات بأفضل المواهب وتأمين فرص عمل لائقة ومناسبة لجميع المهن والوظائف والمناصب.";
      }
      return texts;
    },
  },
  created() {
    this.$store.state.stageCounter = 1;
  },
};
</script>

<style>
.aboutUs--section1 {
  width: 100%;
  text-align: center;
  padding: 50px 0;
  background: #f39f3c;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutUs__button {
  color: #f39f3c !important;
  text-transform: none;
  transition: 0.5s;
}
.aboutUs__button:hover {
  background: #599ca7 !important;
  transition: 0.5s;
  color: white !important;
}
.aboutUs--section2 {
  margin: 100px 0;
  color: #599ca7;
}
.aboutUs_section2TextCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aboutUs_section2ImageCol {
  display: flex;
  justify-content: center;
}
.section2-imageWrapper {
  height: auto;
  width: 250px;
}
.section2--image {
  width: 100%;
}
.aboutUs--section3 {
  margin-top: 100px;
  padding: 50px 0;
  width: 100%;
  background: #599ca7;
  text-align: center;
}
.section3--col:hover .section3--checkIcon {
  color: green;
  transition: 0.5s;
  transform: scale(1.2);
}
.section3--singleContent {
  display: flex;
  align-items: center;
}
.section3--checkIcon {
  font-size: 1.1rem;
  padding-right: 10px;
  transition: 0.5s;
}

.section3--paragraph {
  width: 75%;
  margin: 0 auto;
  text-align: start;
}
.aboutUsArText,
.aboutUsArParagraph {
  text-align: start;
}
.aboutUsArParagraph {
  font-size: 1.1rem;
}
.section3--checkIconAr {
  padding-right: 0;
  padding-left: 10px;
}
@media only screen and (max-width: 457px) {
  .section3--checkIcon {
    font-size: 1.2rem;
  }
}
</style>