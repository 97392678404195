<template>
  <div class="termsWrapper">
    <h2 class="termsHeader">{{ $t("terms.title") }}</h2>
    <div class="termsText">{{ $t("terms.generalText") }}</div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.termsWrapper {
  background: #f39f3c;
  padding: 30px 20%;
  text-align: start;
  white-space: pre-line;
}
.termsHeader {
  text-align: center;
}
</style>