<template>
  <nav
    class="
      navBar--customized
      sticky-top
      navbar navbar-expand-lg navbar-light
      bg-light
    "
  >
    <div class="navbar-brandWrapper">
      <img
        class="navbar-brand"
        src="@/assets/Connectme.png"
        @click="$router.push('/')"
      />
    </div>
    <button
      class="navbar-toggler"
      :class="{ 'navbar-toggler-iconRTL': $vuetify.rtl }"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse navbar-toggleable-sm"
      id="navbarSupportedContent"
    >
      <ul
        class="navbar-nav navItems-wrapper"
        :class="{ 'navItems-wrapperRTL': $vuetify.rtl }"
      >
        <router-link
          active-class="activeLink"
          to="/"
          exact
          class="navbar--element"
          tag="span"
          :class="{ 'navbar--elementRTL': $vuetify.rtl }"
          >{{ watchLang.home }}</router-link
        >
        <router-link
          active-class="activeLink"
          to="/StartYourCarrer"
          class="navbar--element"
          tag="span"
          :class="{ 'navbar--elementRTL': $vuetify.rtl }"
          >{{ watchLang.startYourCareer }}</router-link
        >
        <router-link
          active-class="activeLink"
          to="/AboutUs"
          class="navbar--element"
          tag="span"
          :class="{ 'navbar--elementRTL': $vuetify.rtl }"
          >{{ watchLang.aboutUs }}</router-link
        >

        <li class="d-flex">
          <v-select
            v-model="selectValue"
            :items="languages"
            hide-details
            color="#599CA7"
            class="navbar--select"
            light
            @change="switchLanguage"
          ></v-select>
          <app-country-flag
            :country="countryIcon"
            size="normal"
          ></app-country-flag>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import CountryFlag from "vue-country-flag";
export default {
  components: {
    appCountryFlag: CountryFlag,
  },
  data() {
    return {
      selectValue: "EN",
      languages: ["EN", "AR"],
      countryIcon: "us",
    };
  },
  methods: {
    switchLanguage(language) {
      let x = document.getElementById("app");
      this.selectValue = language;
      this.$vuetify.rtl = !this.$vuetify.rtl;
      if (language == "AR") {
        i18n.locale = "ar";
        x.style.fontFamily = "Tajawal, sans-serif";
        this.countryIcon = "ae";
      } else {
        i18n.locale = "en";
        x.style.fontFamily = "Roboto, sans-serif";
        this.countryIcon = "us";
      }
      // $(".navbar-collapse").collapse("hide");
    },
  },
  computed: {
    watchLang() {
      let texts = {
        home: "Home",
        startYourCareer: "Start Your Career",
        aboutUs: "About Us",
      };
      if (this.$vuetify.rtl == true) {
        texts.home = "الأساسية";
        texts.startYourCareer = "ابدأ رحلتك المهنية";
        texts.aboutUs = "من نحن";
      }
      return texts;
    },
  },
  watch: {
    $route() {
      $(".navbar-collapse").collapse("hide");
    },
  },
};
</script>

<style>
.navBar--customized {
  background: white !important;
  margin: 0;
}
.navbar-toggler-iconRTL {
  margin-right: 0;
  margin-left: 10px;
}
.navbar {
  padding: 0 !important;
}
.navbar-brandWrapper {
  height: auto;
  width: 50px;
  margin-left: 3%;
}
.navbar-brand {
  width: 100%;
}
.activeLink {
  color: #599ca7 !important;
}
.navbar--select {
  padding-top: 0 !important;
  margin-top: 0 !important;
  max-width: 130px !important;
}
.navbar--element {
  color: #f39f3c;
  margin: 5px 0;
  transition: 0.5s;
}
.navbar--elementRTL {
  text-align: start;
}
.navbar-toggler {
  font-size: 15px;
  margin-right: 10px;
}
.navItems-wrapperRTL {
  padding-left: 0;
  padding-right: 24px;
}
@media only screen and (min-width: 991px) {
  .navbar-brand {
    cursor: pointer;
  }
  .navItems-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  .navbar--element {
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
  }
  .navbar--elementRTL {
    font-size: 1.3rem;
  }

  .navbar--element:hover {
    color: #097c9c;
    transition: 0.5s;
  }
}
</style>