import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { i18n } from '@/plugins/i18n.js';
import axiosHelper from './network/axios-helper'
import VueAnalytics from 'vue-analytics';


Vue.use(VueAnalytics, {
  id: 'G-YM6X5HVQEY',
  router
});
Vue.use(VueSweetalert2);
Vue.use(axiosHelper)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
