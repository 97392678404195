import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import startCareer from '../views/startCareer.vue'
import aboutUs from '../views/aboutUs.vue'
import terms from '../views/terms'
import policy from '../views/policy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,

  },
  {
    path: '/TermsAndConditions',
    component: terms,
  },
  {
    path: '/PrivacyAndPolicy',
    component: policy,
  },
  {
    path: '/StartYourCarrer',
    name: 'StartYourCarrer',
    component: startCareer
  },
  {
    path: '/AboutUs',
    name: 'About Us',
    component: aboutUs
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

export default router
