<template>
  <div>
    <div class="homePage__section1Wrapper">
      <v-container>
        <v-row>
          <v-col sm="6">
            <div class="homePage__section1TextWrapper">
              <h1><span class="homePage__section1Span">C</span>onnect Me</h1>
              <h5>{{ watchLang.homeText }}</h5>
              <v-btn
                rounded
                x-large
                color="#F39F3C"
                class="homePage__button animate__animated animate__swing"
                :class="{ homeButtonRTL: $vuetify.rtl }"
                @click="$router.push('/StartYourCarrer')"
                >{{ watchLang.homeButtonText }}</v-btn
              >
            </div>
          </v-col>
          <v-col sm="6">
            <div class="homePage__section1LottieWrapper">
              <lottie
                :options="defaultOptions"
                :height="300"
                :width="300"
                v-on:animCreated="handleAnimation"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="homePage__section2Wrapper">
      <h1>{{ watchLang.howWeWorkHead }}</h1>
      <p class="section2--generalText">
        {{ watchLang.howWeWorkGeneralText }}
      </p>
      <v-container>
        <v-row>
          <v-col
            md="4"
            cols="12"
            v-for="(data, index) in cardData"
            :key="index"
          >
            <div class="homePage__howWeWorkCard">
              <i class="cardIcon" :class="data.icon" aria-hidden="true"></i>
              <h3>{{ data.title }}</h3>
              <p>
                {{ data.description }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="homePage__section3Wrapper">
      <h1>{{ watchLang.ourValue }}</h1>
      <v-container>
        <v-row>
          <v-col
            md="4"
            cols="12"
            v-for="(data, index) in circleData"
            :key="index"
          >
            <div class="section3__OptionWrapper">
              <div class="section3__circle">
                <i :class="data.icon" aria-hidden="true"></i>
              </div>
              <h3>{{ data.title }}</h3>
              <p class="ourValue-cardParagraph">
                {{ data.description }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import footer from "../components/footer";
import Lottie from "vue-lottie";
import * as animationData from "../assets/62884-boy-marketing.json";
export default {
  components: {
    appFooter: footer,
    lottie: Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
  computed: {
    circleData() {
      if (this.$vuetify.rtl) {
        let circleDataArr = [
          {
            icon: "fa fa-star",
            title: "السرعة",
            description: `كل خطوة في عملنا مصممة ليتم تنفيذها بزمن قياسي وبأقل مجهود ممكن.`,
          },

          {
            icon: "fa fa-handshake-o",
            title: "الشفافية",
            description: `نعتمد في كافة أعمالنا على شفافية مطلقة مع كافة الجهات المعنية والمستفيدة.`,
          },
          {
            icon: "fa fa-globe",
            title: "الانتشار",
            description: `بشبكة واسعة من الشركات على امتداد الجغرافية الإماراتية سنعمل على نموها بشكل مستمر.`,
          },
        ];
        return circleDataArr;
      } else {
        let circleDataArr = [
          {
            icon: "fa fa-star",
            title: "Speed",
            description: `Every step in our work is designed to be executed in record time and with minimal effort.`,
          },

          {
            icon: "fa fa-handshake-o",
            title: "Transparency",
            description: `In all our work, we rely on absolute transparency with all stakeholders and beneficiaries.`,
          },
          {
            icon: "fa fa-globe",
            title: "Spread",
            description: `With a wide network of companies across the UAE geography, we will work on its continuous growth.`,
          },
        ];
        return circleDataArr;
      }
    },
    cardData() {
      let cardDataArr = [
        {
          icon: "fa fa-upload uploadIcon",
          title: "Upload Your CV",
          description: `Create a professional resume that showcases all your amazing 
accomplishments and the work you've done in your career Upload it to 
the platform.
Haven't you created your unique CV yet? Don't worry, we can help you 
with a professional cadre in human resources management to create 
your CV. Contact us on: customerservice@connectmeae.com`,
        },
        {
          icon: "fa fa-hand-pointer-o",
          title: "Choose your plan",
          description: `After uploading your resume, you will receive a list of the offers that we 
will present to you attached with prices for each package that you can 
choose from.
These packages are designed based on various factors such as years of 
experience, career path, career field and available vacancies.`,
        },
        {
          icon: "fa fa-check",
          title: "Start a new journey",
          description: `When you choose one of the packages and complete the payment 
process, we will directly send your CV to the largest number of 
companies within the package that you selected.
By requesting it, you have various options according to the available 
vacancies.`,
        },
      ];
      if (this.$vuetify.rtl) {
        cardDataArr = [
          {
            icon: "fa fa-upload uploadIcon",
            title: "قم بتحميل السيرة الذاتية",
            description: `اصنع سيرتك الذاتية بشكل احترافي والتي تستعرض جميع إنجازاتك المذهلة والأعمال التي قمت بها محدداً في مسيرتك المهنية، و قم برفعها على المنصة.
            لم تقم ببنائها بعد ؟ يمكننا مساعدتك مع كادر احترافي في إدارة الموارد البشرية. تواصل معنا على customerservice@connectmeae.com`,
          },
          {
            icon: "fa fa-hand-pointer-o",
            title: `اختر الخطة المناسبة لك`,
            description: `بعد رفع السيرة الذاتية ستصلك قائمة بالعروض التي سنقدمها لك مرفقة بأسعار لكل باقة يمكنك الاختيار منها.
            تصمم هذه الباقات بناءً على عوامل مختلفة كسنين الخبرة والمسيرة المهنية والمجال الوظيفي والشواغر المتاحة.`,
          },
          {
            icon: "fa fa-check",
            title: "ابدأ برحلة جديدة",
            description:
              "عند اختيارك لأحد الباقات وإتمام عملية الدفع، سنقوم مباشرةً بإرسال سيرتك الذاتية لأكبر عدد من الشركات ضمن الباقة التي قمت بطلبها لتصبح لديك خيارات متنوعة وفقاً للشواغر المتاحة.",
          },
        ];
      }

      return cardDataArr;
    },
    watchLang() {
      let texts = {
        homeText: "Start your career with us today!",
        homeButtonText: "Get Started",
        howWeWorkHead: "How We Work",
        howWeWorkGeneralText: `We believe that all individuals have equal rights to stability within a 
distinguished work that suits their distinct abilities and in a constructive 
and positive environment, and we greatly appreciate the time of each person and the effort that they spend while searching 
for the work they dream of, so we have developed a mechanism that 
contributes to the delivery of your potential strength and skills to.
The entities you are looking for in 3 simple steps:`,
        ourValue: "Our values",
      };
      if (this.$vuetify.rtl) {
        texts.homeText = "ابدأ رحلتك المهنية معنا اليوم";
        texts.homeButtonText = "ابدأ رحلتك";
        texts.howWeWorkHead = "كيف نعمل";
        texts.howWeWorkGeneralText = ` نؤمن أن لجميع الأفراد حقوق متساوية بالاستقرار ضمن عمل متميز يناسب قدراتهم المتميزة وفي بيئة بَناءة وإيجابية، ونقدر بشكل كبير وقت كل شخص
        والجهد الذي يبذله أثناء بحثه عن العمل الذي يحلم به، لذا قمنا بتطوير آلية تساهم في إيصال قواك الكامنة ومهاراتك إلى الجهات التي تبحث عنها
        بثلاث خطوات بسيطة:`;
        texts.ourValue = "قيمنا";
      }
      return texts;
    },
  },
  created() {
    this.$store.state.stageCounter = 1;
  },
};
</script>
<style>
.homePage__section1Wrapper,
.homePage__section3Wrapper {
  box-shadow: inset 0 0 0 2000px rgba(33, 123, 138, 0.75);
}
.homePage__section1TextWrapper,
.homePage__section1LottieWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homePage__section1TextWrapper h1 {
  font-size: 3rem;
}
.homePage__section1Span {
  color: #f39f3c;
  font-size: 3.5rem;
}

.homePage__button {
  text-transform: none !important;
  transition: 0.5s !important;
}
.homePage__button:hover {
  background: white !important;
  color: #f39f3c !important;
  transition: 0.5s;
}
.homePage__section2Wrapper {
  color: #599ca7;
}
.homePage__section2Wrapper,
.homePage__section3Wrapper {
  padding: 30px 0;
}

.homePage__section2Wrapper h1,
.homePage__section3Wrapper h1 {
  text-align: center;
  margin: 10px;
}
.section2--generalText {
  text-align: center;
  padding: 0 30px;
}
.homePage__howWeWorkCard {
  box-shadow: 5px 5px 10px 3px #88888861;
  border-radius: 20px;
  height: 100%;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: 0.3s;
}

.homePage__howWeWorkCard:hover .cardIcon {
  color: rgba(33, 123, 138, 1);
  transition: 0.5s;
  transform: scale(1.2);
}
.homePage__howWeWorkCard:hover {
  transform: translateY(-10px);
}
.cardIcon {
  font-size: 2.5rem;
  margin-bottom: 20px;
  transition: 0.5s;
}
.section3__OptionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section3__OptionWrapper:hover .section3__circle i {
  color: rgba(33, 123, 138, 1);
  transition: 0.5s;
  transform: scale(1.2);
}
.section3__OptionWrapper h3 {
  text-align: center;
  padding: 0 23px;
}

.section3__circle {
  background: white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.ourValue-cardParagraph {
  /* white-space: pre-wrap; */
  text-align: center;
}
.section3__circle i {
  color: #f39f3c;
  font-size: 3.5rem;
  transition: 0.5s;
}
.homeButtonRTL {
  font-size: 1.2rem !important;
}

@media only screen and (min-width: 768px) {
  .homePage__section1TextWrapper h1 {
    font-size: 4.5rem;
  }
  .homePage__section1Span {
    font-size: 4.5rem;
  }
  .section2--generalText {
    padding: 0;
    width: 60%;
    margin: 0 auto;
  }
}
</style>