<template>
  <div class="footer--wrapper" :class="{ 'footer--wrapperAr': $vuetify.rtl }">
    <h1 v-if="$vuetify.rtl">
      مع <span class="footer--C">كو</span>ننيكت مي ستشرق في مهنتك
    </h1>
    <h1 v-else>
      With <span class="footer--C">C</span>onnect me you will shine
    </h1>
    <v-container>
      <v-row>
        <v-col md="4" cols="12" class="footer--col">
          <h3>{{ watchLang.findUs }}</h3>
          <p v-if="$vuetify.rtl">
            الإمارات العربية المتحدة دبي،<br />
            المرقبات ، مركز وربة للأعمال - مكتب M26<br />
            رقم المكتب: 009714-2228242
          </p>
          <p v-else>
            U.A.E Dubai, Al Muraqabat,
            <br />
            Warba Business Center - Office M26
            <br />Office Number : 009714-2228242
          </p>
          <p>
            <i class="fa fa-envelope footer--socialIcon" aria-hidden="true"></i>
            customerservice@connectmeae.com
          </p>
          <div class="footer--socialsWrapper">
            <div class="footer--socialIconWrapper1">
              <i
                class="fab fa-facebook-f footer--socialIcon"
                @click="goToLink('https://www.facebook.com/ConnectmeaePortal/')"
              ></i>
            </div>
            <div
              class="footer--socialIconWrapper2"
              :class="{ 'footer--socialIconWrapper2Ar': $vuetify.rtl }"
            >
              <i
                class="fab fa-instagram footer--socialIcon"
                @click="goToLink('https://www.instagram.com/connectmeae/')"
              ></i>
            </div>
            <div
              class="footer--socialIconWrapper3"
              :class="{ 'footer--socialIconWrapper3Ar': $vuetify.rtl }"
            >
              <i
                class="fab fa-linkedin footer--socialIcon"
                @click="
                  goToLink(
                    'https://www.linkedin.com/in/connectme-uae-06b4a8212/'
                  )
                "
              ></i>
            </div>
          </div>
        </v-col>
        <v-col md="4" cols="12" class="footer--col">
          <h3>{{ watchLang.information }}</h3>
          <p class="footer--informationItem" @click="$router.push('/AboutUs')">
            {{ watchLang.information1 }}
          </p>
          <app-privacy-and-policy></app-privacy-and-policy>
          <app-terms-and-conditions></app-terms-and-conditions>
          <p
            class="footer--informationItem"
            @click="$router.push('/StartYourCarrer')"
          >
            {{ watchLang.information5 }}
          </p>
        </v-col>

        <v-col md="4" cols="12" class="footer--col">
          <h3>{{ watchLang.aboutUs }}</h3>
          <p>
            {{ watchLang.aboutUsParagpraph }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import termsAndConditions from "../components/termsAndConditions";
import privacyAndPolicy from "../components/privacyAndPolicy";
export default {
  components: {
    appTermsAndConditions: termsAndConditions,
    appPrivacyAndPolicy: privacyAndPolicy,
  },
  methods: {
    goToLink(link) {
      window.open(link, "_blank");
    },
  },
  computed: {
    watchLang() {
      let texts = {
        aboutUs: "About Us",
        aboutUsParagpraph:
          "A company specialized in linking companies with people looking for work in the fastest and least expensive way, we provide companies with the best talent and insurance. Decent and suitable employment opportunities for all professions, jobs and positions.",
        information: "Information",
        information1: "About Us",
        information4: "Contact us",
        information5: "Get Started",
        findUs: "Find Us",
        footerButton: "Get Started",
      };
      if (this.$vuetify.rtl) {
        texts.aboutUs = "من نحن";
        texts.aboutUsParagpraph =
          "شركة مختصة بربط الشركات بالأشخاص الباحثين عن عمل بأسرع الطرق وأقل التكاليف، نقوم برفد الشركات بأفضل المواهب وتأمين فرص عمل لائقة ومناسبة لجميع المهن والوظائف والمناصب.";
        texts.information = "معلومات";
        texts.information1 = "من نحن";
        texts.information4 = "تواصل معنا";
        texts.information5 = "ابدأ رحلتك";
        texts.findUs = "تجدنا في";
        texts.footerButton = "ابدأ";
      }
      return texts;
    },
  },
};
</script>

<style>
.footer--wrapper {
  background: #f39f3c;
  padding: 30px 0;
  width: 100%;
}
.footer--wrapperAr p {
  font-size: 1.1rem;
}
.footer--wrapper h1 {
  text-align: center;
}

.footer--informationItem {
  cursor: pointer;
  transition: 0.5s;
}
.footer--informationItem:hover {
  color: #599ca7;
}
.footer--socialsWrapper {
  display: flex;
  justify-content: center;
}
.footer--socialIconWrapper1,
.footer--socialIconWrapper2,
.footer--socialIconWrapper3 {
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer--socialIconWrapper2,
.footer--socialIconWrapper3 {
  margin-left: 20px;
}
.footer--socialIconWrapper2Ar,
.footer--socialIconWrapper3Ar {
  margin-left: 0;
  margin-right: 20px;
}
.footer--socialIconWrapper1:hover .footer--socialIcon,
.footer--socialIconWrapper2:hover .footer--socialIcon,
.footer--socialIconWrapper3:hover .footer--socialIcon {
  transform: scale(1.2);
  color: #599ca7;
  transition: 0.5s;
}
.footer--socialIcon {
  font-size: 1.2rem;
  transition: 0.5s;
}
.footer--C {
  color: #599ca7;
}
.footer--col {
  text-align: center;
}
</style>