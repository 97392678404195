<template>
  <div class="stepper__wrapper">
    <div class="stepperCircle" :class="{ orangeBackground: switchColor }">
      <i class="fas fa-check" v-if="switchColor"></i>
      <span v-else>{{ elementNumber }}</span>
    </div>
    <div
      class="completeLine"
      :class="{ completeLineActive: switchColor }"
      v-if="completeLine && $vuetify.rtl == false"
    ></div>

    <div
      class="completeLineRTL"
      :class="{ completeLineActiveRTL: switchColor }"
      v-if="completeLine && $vuetify.rtl == true"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    elementNumber: Number,
    switchColor: Boolean,
    completeLine: Boolean,
  },

  data() {
    return {};
  },
};
</script>

<style>
.stepper__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepperCircle {
  width: 40px;
  height: 40px;
  background: white;
  color: #eb943c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .completeLine,
.completeLineRTL {
  width: 80px;
  height: 5px;
  margin: 0 5px;
  border-radius: 50px;
  background-size: 200% 100%;
  transition: all 1s ease;
} */
.completeLine {
  background: linear-gradient(to left, white 50%, #eb943c 50%);
  background-position: right bottom;
    width: 80px;
  height: 5px;
  margin: 0 5px;
  border-radius: 50px;
  background-size: 200% 100%;
  transition: all 1s ease;
}

.completeLineRTL {
  background: linear-gradient(to right, white 50%, #eb943c 50%);
  background-position: left bottom;
    width: 80px;
  height: 5px;
  margin: 0 5px;
  border-radius: 50px;
  background-size: 200% 100%;
  transition: all 1s ease;
}
.orangeBackground {
  background: #eb943c;
  color: white;
  background-position: left bottom;
}
.completeLineActive {
  background-position: left bottom;
}
.completeLineActiveRTL {
  background-position: right bottom;
}
@media only screen and (min-width: 768px) {
  .completeLine {
    width: 150px;
  }
  .completeLineRTL {
    width: 150px;
  }
}
@media only screen and (min-width: 1024px) {
  .completeLine {
    width: 250px;
  }
  .completeLineRTL {
    width: 250px;
  }
}
</style>