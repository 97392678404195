<template>
  <div class="policyWrapper">
    <h2 class="policyHeader">{{ $t("policy.title") }}</h2>
    <div class="policyText">{{ $t("policy.generalText") }}</div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.policyWrapper {
  background: #f39f3c;
  padding: 30px 20%;
  text-align: start;
  white-space: pre-line;
}
.policyHeader {
  text-align: center;
}
</style>