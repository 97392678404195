<template>
  <div>
    <div class="plan-card__wrapper">
      <div class="plan-card__header" :style="`background:${planInfo.color}`">
        <div class="card-header__icon-wrapper">
          <img :src="planInfo.icon" class="card-header__icon" />
        </div>
      </div>
      <div class="plan-card__body-wrapper">
        <p v-if="localeText == 'en'" class="plan-card__name">
          {{ planInfo.name }}
        </p>
        <p v-else class="plan-card__name">{{ planInfo.name_ar }}</p>
        <div class="plan-card__price" v-if="currencySign == 'USD'">
          {{ planInfo.price }} {{ currencySign }}
        </div>
        <div class="plan-card__price" v-if="currencySign == 'AED'">
          {{ planInfo.price_aed }} {{ currencySign }}
        </div>
        <div class="plan-card__description" v-if="localeText == 'en'">
          {{ planInfo.description }}
        </div>
        <div class="plan-card__description" v-else>
          {{ planInfo.description_ar }}
        </div>
      </div>
      <v-dialog v-model="dialog" width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#227B8B"
            class="align-self-flex-start"
            width="80%"
            style="text-transform: unset"
            rounded
            @click="selectPlan"
            >{{ $t("planApply") }}</v-btn
          >
        </template>
        <v-card class="p-4" light>
          <v-card-title>
            <h2 class="font-weight-medium black--text">
              {{ $t("checkOut") }}
            </h2>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-credit-card-outline"
                  v-model="checkOut.cardNumber"
                  type="text"
                  dense
                  :label="$t('Number')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-account"
                  v-model="checkOut.cardName"
                  type="text"
                  dense
                  :label="$t('CardholderName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  placeholder="06"
                  :maxlength="2"
                  prepend-icon="mdi-calendar-range"
                  v-model="checkOut.expiryMonth"
                  type="text"
                  dense
                  :label="$t('ExpiryMonth')"
                  hint="mm"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-calendar-range"
                  v-model="checkOut.expiryYear"
                  type="text"
                  dense
                  :label="$t('ExpiryYear')"
                  hint="yy"
                  persistent-hint
                  placeholder="21"
                  :maxlength="2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-credit-card-search-outline"
                  v-model="checkOut.ccv"
                  type="text"
                  dense
                  :label="$t('CCV')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="#F39F3C"
              outlined
              width="30%"
              :class="{ plansButtonRTL: $vuetify.rtl == true }"
              style="text-transform: none; transition: 0.5s"
              :disabled="paymentBtn"
              @click="emitPaymentInfo"
              >{{ $t("pay") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";
export default {
  data() {
    return {
      currencySign: this.$store.state.currency,
      dialog: false,
      checkOut: {
        selectedPlan: null,
        cardName: "",
        cardNumber: "",
        ccv: "",
        expiryYear: "",
        expiryMonth: "",
      },
    };
  },
  props: {
    planInfo: Object,
  },
  methods: {
    selectPlan() {
      this.checkOut.selectedPlan = this.planInfo.id;
      // console.log("OK !", this.checkOut.selectedPlan);
    },
    emitPaymentInfo() {
      this.$emit("paymentInfo", this.checkOut);
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.checkOut.cardName = "";
        this.checkOut.cardNumber = "";
        this.checkOut.ccv = "";
        this.checkOut.expiryYear = "";
        this.checkOut.expiryMonth = "";
      }
    },
  },
  computed: {
    localeText() {
      return i18n.locale;
    },
    paymentBtn() {
      if (
        !this.checkOut.cardName ||
        !this.checkOut.cardNumber ||
        !this.checkOut.ccv ||
        !this.checkOut.expiryYear ||
        !this.checkOut.expiryMonth
      )
        return true;
    },
  },
};
</script>

<style>
.plan-card__wrapper {
  width: 250px;
  height: 350px;
  background: white;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid purple; */
}
.plan-card__header {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  /* padding-top: 10%; */
}
.plan-card__body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-header__icon-wrapper {
  height: auto;
  width: 100px;
  margin-bottom: 5%;
}
.card-header__icon {
  width: 100%;
}
.plan-card__name {
  font-size: 1.3rem;
  padding-top: 3%;
  color: black;
  font-weight: bold;
}
.plan-card__price {
  color: black;
  font-size: 1.6rem;
  font-weight: bold;
  /* padding-top: 10%; */
}
.plan-card__description {
  color: black;
  padding-top: 15%;
  margin-bottom: 40%;
  font-weight: bold;
}
.plansButtonRTL {
  font-size: 1.2rem !important;
}
</style>