import axios from 'axios'
import Vue from 'vue'
import vuetify from '../plugins/vuetify'
// import store from '../store/store'



axios.defaults.baseURL = 'https://api.connectmeae.com/api'
// axios.defaults.headers.common['Authorization'] = store.state.userInfo.accessToken

axios.interceptors.request.use(
    request => {
        // 
        return request
    }
)

axios.interceptors.response.use(
    response => {
        // 
        console.log(Vue);
        return response
    },
    error => {
        const status = error.response.status
        const message = error.response.data.message
        if (status === 400) {
            Vue.swal.fire({
                icon: "error",
                position: "top",
                title: message,
            });
        }
        else if (status === 422) {

            Vue.swal.fire({
                icon: "error",
                position: "top",
                title: message,
            });
        }
        else if (status === 500) {
            if (vuetify.framework.rtl) {
                Vue.swal.fire({
                    icon: "error",
                    position: "top",
                    title: 'حدث خطأ الرجاء المحاولة مرة أخرى',
                });
            } else {
                Vue.swal.fire({
                    icon: "error",
                    position: "top",
                    title: 'Something went wrong please try again',
                });
            }
        }
        return Promise.reject(error)
    }
)

export default {}